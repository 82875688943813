import * as c from "../constants";

const initialState = () => {
    return {
        isRightDrawerOpen: false,
        loading: false
    }
}

export const appReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.TOGGLE_DRAWER:
            return { ...state, isRightDrawerOpen: action.payload }
        case c.TOGGLE_LOADING_FORM:
            return { ...state, loading: action.payload }
        default:
            return state
    }
}
