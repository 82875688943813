import * as c from "../constants";

export const toggleDrawer = (value) => ({
    type: c.TOGGLE_DRAWER,
    payload: value
})

export const toggleLoading = (value) => ({
    type: c.TOGGLE_LOADING_FORM,
    payload: value
})